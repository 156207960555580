import { useEffect, useState, useRef } from 'react';
import CandidateImage from "../assets/images/candidate.jpg";
import playButton from "../assets/images/play-button.png";
// import sampleVideo from "../assets/background/sample.mp4";
import useOnClickOutside from '../hooks/useOnClickOutside';
import useToggle from '../hooks/useToggle';
import { useParams } from 'react-router-dom';
import useFetchData from '../hooks/useFetchData';
import ChartComponent from '../components/Chart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
import { faVolumeMute, faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import introAudio from "../assets/audio/intro.mp3"
import audioFile1 from "../assets/audio/q1.mp3";
import audioFile2 from "../assets/audio/q2.mp3";
import { formatDates } from '../helper';

function CandidateDetails() {
    const [candidate, setCandidate] = useState<any>(null);
    const [isToggled, toggle] = useToggle(false);
    const [startSeconds, setStartSeconds] = useState<number>(0);
    const [endSeconds, setEndSeconds] = useState<number>(0);
    const [isPlaying, setIsPlaying] = useState<boolean>(false); // Playback state
    const [isMuted, setIsMuted] = useState<boolean>(false);
    const [currentTime, setCurrentTime] = useState<number>(0); // Current playback time
    // const [duration, setDuration] = useState<number>(0); // Total duration of the video
    const [audioPlaying, setAudioPlaying] = useState<boolean>(false);
    const [audio, setAudio] = useState<HTMLAudioElement | null>(null);


    const { id } = useParams()

    const { data, loading, error } = useFetchData(`/interview/interview-details/${id}`);

    useEffect(() => {
        if (data) {
            setCandidate(data);
        }
    }, [data]);

    const modalRef = useRef<HTMLDivElement>(null);
    useOnClickOutside(modalRef, () => {
        toggle();
        if (audioPlaying) {
            handleStopAudio()
        }
    });

    const handlePlayButtonClick = (start: number, end: number, audio: String) => {
        setStartSeconds(start);
        setEndSeconds(end);
        toggle();
        if (!audioPlaying) {
            setAudioPlaying(true);
            let audioFile = `data:audio/mp3;base64,${audio}`;
            const newAudio = new Audio(audioFile);
            setAudio(newAudio);
            newAudio.play();
            newAudio.onended = () => {
                setAudioPlaying(false);
                // Start playing the video once the audio has ended
                const video = document.getElementById('videoPlayer2') as HTMLVideoElement;
                if (video) {
                    video.play();
                    setIsPlaying(true);
                }
            };
        }
    }

    const handleStopAudio = () => {
        if (audio) {
            audio.currentTime = 0;
            audio.pause();
            setAudioPlaying(false)
        }
    };


    const handlePlayPause = () => {
        const video = document.getElementById('videoPlayer2') as HTMLVideoElement;
        if (video && !video.paused) {
            video.pause();
            setIsPlaying(false);
        } else if (video && video.readyState >= 2) {
            video.play();
            setIsPlaying(true);
        }
    };

    const handleMuteToggle = () => {
        const video = document.getElementById('videoPlayer2') as HTMLVideoElement;
        if (video) {
            video.muted = !video.muted;
            setIsMuted(video.muted);
        }
    };
    const handleTimeUpdate = (e: React.SyntheticEvent<HTMLVideoElement>) => {

        if (e.currentTarget.currentTime < startSeconds || e.currentTarget.currentTime > endSeconds) {
            e.currentTarget.currentTime = startSeconds;
            e.currentTarget.pause();
            setIsPlaying(false)
        }
        const video = e.target as HTMLVideoElement;
        setCurrentTime(video.currentTime);
        // setDuration(video.duration);


    };

    const handleSeek = (event: React.ChangeEvent<HTMLInputElement>) => {
        const time = parseFloat(event.target.value);
        setCurrentTime(time);
        const video = document.getElementById('videoPlayer2') as HTMLVideoElement;
        if (video) {
            video.currentTime = time;
        }
    };
    if (error) {
        return <p>{error}</p>
    }

    return (
        <div className="dashboard">
            {loading ? (
                <div className="loader-container">
                    <div className="loader"></div>
                </div>
            ) : (
                <div>
                    <div className="candidate-container">
                        <div className="candidate-info">
                            <img src={CandidateImage} alt="Candidate Photo" width="100px" />
                            <p>First Name: {candidate?.first_name}</p>
                            <p>Last Name: {candidate?.last_name}</p>
                            <p>Position Applied: {'Software Developer'}</p>
                        </div>
                        <div className="chart-container">
                            {candidate?.analytics_data.length > 0 ? (
                                <ChartComponent analytics_data={candidate?.analytics_data} />
                            ) : (
                                <p>No data available</p>
                            )}
                        </div>

                    </div>
                    <div className="interview-info">
                        <p>Interview Time: {formatDates(candidate?.interview_slot)}</p>
                        <p>Global Score: {candidate?.global_score}</p>
                    </div>
                    <div className="resume-summary">
                        <h3>Resume Summary</h3>
                        <p>{candidate?.resume_summary}</p>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Question</th>
                                <th>Video Clip</th>
                                <th>Score</th>
                            </tr>
                        </thead>
                        <tbody>
                            {candidate?.questions.map((question: any, index: number) => (
                                <tr key={index}>
                                    <td>{question.questionNumber} {question.question}</td>
                                    <td>
                                        <td className='playButton' onClick={() => handlePlayButtonClick(parseInt(question.from_sec), parseInt(question.to_sec), question.audio)}>
                                            {candidate.url !== 'N/A' ? (
                                                <img src={playButton} alt="" width={20} height={20} />
                                            ) : (
                                                'N/A'
                                            )}
                                        </td>

                                    </td>
                                    <td>{question.score}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="recommendation">
                        <h3>Recommendation</h3>
                        <p>{candidate?.recommendation}</p>
                    </div>
                </div>)}
            {isToggled && (
                <div className="modal" >
                    <div className="modal-content" ref={modalRef}>
                        <video id='videoPlayer2' autoPlay width={640} height={360} preload='auto' onTimeUpdate={handleTimeUpdate} >
                            <source src={candidate?.url} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        {!audioPlaying && (
                            <div>
                                <input type="range" min={startSeconds} max={endSeconds} value={currentTime} onChange={handleSeek} style={{ width: "100%" }} />
                                <div>
                                    <button onClick={handlePlayPause} disabled={audioPlaying}>
                                        {isPlaying ? <FontAwesomeIcon icon={faPause} /> : <FontAwesomeIcon icon={faPlay} />}
                                    </button>
                                    <button onClick={handleMuteToggle}>
                                        {isMuted ? <FontAwesomeIcon icon={faVolumeMute} /> : <FontAwesomeIcon icon={faVolumeUp} />}
                                    </button>
                                </div>
                            </div>
                        )}

                    </div>
                </div>
            )}
        </div>

    );
}

export default CandidateDetails;