import { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import useFetchData from '../hooks/useFetchData';
import playButton from "../assets/images/play-button.png"
import useToggle from '../hooks/useToggle';
import useOnClickOutside from '../hooks/useOnClickOutside';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
import { faVolumeMute, faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import { formatDates } from '../helper';


function ListingCandidates() {
  const [interviews, setInterviews] = useState<any>([]);
  const tableRef = useRef<HTMLTableElement>(null);
  const dataTableRef = useRef<any>(null);
  const [isToggled, toggle] = useToggle(false);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState<string>("");
  const [currentTime, setCurrentTime] = useState<number>(0); // Current playback time
  const [duration, setDuration] = useState<number>(0); // Total duration of the video
  const { data, loading } = useFetchData('/interview/list-interviews');
  const [isPlaying, setIsPlaying] = useState<boolean>(true); // Playback state
  const [isMuted, setIsMuted] = useState<boolean>(false);

  useEffect(() => {
    if (data) {
      setInterviews(data);
    }
  }, [data]);

  useEffect(() => {
    if (interviews.length > 0 && tableRef.current && !dataTableRef.current) {
      dataTableRef.current = initializeDataTable(tableRef.current);
    }
  }, [interviews]);

  const initializeDataTable = (table: HTMLTableElement) => {
    return (window as any).$((tableRef.current as any)).DataTable({
      searching: true,
      order: [[6, 'desc']]
    });
  };

  const modalRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(modalRef, () => toggle());

  const handlePlayButtonClick = (url: string) => {
    setSelectedVideoUrl(url);
    toggle();
  };


  const handlePlayPause = () => {
    const video = document.getElementById('videoPlayer') as HTMLVideoElement;
    if (video) {
      if (video.paused) {
        video.play();
        setIsPlaying(true);
      } else {
        video.pause();
        setIsPlaying(false);
      }
    }
  };

  const handleMuteToggle = () => {
    const video = document.getElementById('videoPlayer') as HTMLVideoElement;
    if (video) {
      video.muted = !video.muted;
      setIsMuted(video.muted);
    }
  };

  const handleTimeUpdate = (event: React.SyntheticEvent<HTMLVideoElement>) => {
    const video = event.target as HTMLVideoElement;
    setCurrentTime(video.currentTime);
    setDuration(video.duration);
  };

  const handleSeek = (event: React.ChangeEvent<HTMLInputElement>) => {
    const time = parseFloat(event.target.value);
    setCurrentTime(time);
    const video = document.getElementById('videoPlayer') as HTMLVideoElement;
    if (video) {
      video.currentTime = time;
    }
  };


  return (
    <div className="container mt-5">
      {loading ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : (
        interviews.length > 0 ? (
          <table ref={tableRef} className="display">
            <thead className="thead-dark">
              <tr>
                <th>INTERVIEW ID</th>
                <th>CANDIDATE NAME</th>
                <th>POSITION BY</th>
                <th>JOB TITLE</th>
                <th>INTERVIEW SLOT</th>
                <th>Global score</th>
                <th>Recommendation</th>
                <th>STATUS</th>
                <th>POSTED ON</th>
                <th>Master URL</th>
              </tr>
            </thead>
            <tbody>
              {interviews.map((item: any, index: number) => (
                <tr key={index}>
                  <td><Link to={`/candidate-details/${item?._id}`} target="_blank">{item?.interview_id}</Link></td>
                  <td>{item?.first_name}</td>
                  <td>{item?.position_by}</td>
                  <td>{item?.job_title}</td>
                  <td>{formatDates(item?.interview_slot)}</td>
                  <td>{item?.global_score}</td>
                  <td style={{ backgroundColor: item?.recommendation_colour }}>{item?.recommendation}</td>
                  <td>{item?.status}</td>
                  <td>{formatDates(item?.posted_on)}</td>
                  <td className='playButton' onClick={() => handlePlayButtonClick(item?.url)}>
                    {item.url !== 'N/A' ? (
                      <img src={playButton} alt="" width={20} height={20} />
                    ) : (
                      'N/A'
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div>No interviews available</div>
        )
      )}
      {isToggled && (
        <div className="modal">
          <div className="modal-content" ref={modalRef}>
            <video id="videoPlayer" autoPlay width={640} height={360} preload="auto" onTimeUpdate={handleTimeUpdate}>
              <source src={selectedVideoUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <input type="range" min={0} max={duration} value={currentTime} onChange={handleSeek} />
            <div>
              <button onClick={handlePlayPause}>{isPlaying ? <FontAwesomeIcon icon={faPause} /> : <FontAwesomeIcon icon={faPlay} />}</button>
              <button onClick={handleMuteToggle}>{isMuted ? <FontAwesomeIcon icon={faVolumeMute} /> : <FontAwesomeIcon icon={faVolumeUp} />}</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ListingCandidates;