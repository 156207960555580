import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

interface Entry {
    timestamp: string;
    frame_no: number;
    face_quadrant: number;
    is_Blinked: number;
    looking: number;
    Blink_ratio: number;
    pitch_pred: number;
    yaw_pred: number;
    roll_pred: number;
}

interface ChartProps {
    analytics_data: Entry[] | undefined;
}

const ChartComponent: React.FC<ChartProps> = ({ analytics_data }) => {
    const chartRef = useRef<HTMLCanvasElement>(null);
    useEffect(() => {

        if (chartRef.current && analytics_data && analytics_data.length > 0) {
            const timestamps = analytics_data.map(entry => {
                // Extracting minutes and seconds from the timestamp
                const [date, time] = entry.timestamp.split(' ');
                const [hours, minutes, secondsWithDecimal] = time.split(':');
                const seconds = secondsWithDecimal.split('.')[0];
                return `${minutes}:${seconds}`; // Format as desired
            });
            const is_Blinked = analytics_data.map(entry => entry.is_Blinked);
            const face_quadrant = analytics_data.map(entry => entry.face_quadrant);
            const looking = analytics_data.map(entry => entry.looking);

            const max = Math.max(...is_Blinked, ...face_quadrant, ...looking);
            const min = Math.min(...is_Blinked, ...face_quadrant, ...looking);

            const myChart = new Chart(chartRef.current, {
                type: 'line',
                data: {
                    labels: timestamps,
                    datasets: [
                        {
                          label: 'Is Blinked',
                          data: is_Blinked,
                          fill: false,
                          borderColor: 'rgb(75, 192, 192)', // Blue color for IsBlinked
                          tension: 0.1
                        },
                        {
                          label: 'Face Quadrant',
                          data: face_quadrant,
                          fill: false,
                          borderColor: 'rgb(255, 99, 132)', // Red color for Face Quadrant
                          tension: 0.1,
                        },
                        {
                          label: 'Looking',
                          data: looking,
                          fill: false,
                          borderColor: 'rgb(54, 162, 235)', // Orange color for Looking
                          tension: 0.1,
                        },
                      ]
                },
                options: {
                    scales: {
                        x: {
                            title: {
                                display: true,
                                text: 'TimeStamps'
                            }
                        },
                        y: {
                            title: {
                                display: true,
                                text: 'IsBlinked'
                            },
                            min: min-1,
                            max: max+1,
                            ticks: {
                                stepSize: 0
                            }
                        }
                    }
                }
            });

            return () => {
                myChart.destroy();
            };
        }
    }, [analytics_data]);


    return (
        <div>
            <h2>Chart</h2>
            <div>
                <canvas ref={chartRef} width="1000" height="250" className='chart-candidate'></canvas>
            </div>
        </div>
    );
};

export default ChartComponent;
