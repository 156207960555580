import {Route, Routes } from 'react-router-dom'
import CandidateDetails from './pages/CandidateDetails'
import ListingCandidates from './pages/ListingCandidates'

const App = () => {
  return (
    <>
      <Routes>
        <Route path='/candidate-details/:id' element={<CandidateDetails />} />
        <Route path='/' element={<ListingCandidates />} />
      </Routes>
    </>
  )
}

export default App