import { useState, useEffect } from 'react';
import axios from 'axios';
import { REACT_APP_BACKEND_URL } from '../constants';


const useFetchData = (endpoint: string) => {
    const [data, setData] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);


    const fetchData = async () => {
        try {
            const {data}: any = await axios.get(`${REACT_APP_BACKEND_URL}${endpoint}`)
            if (data.data.length !==0) {
                console.log(data.data)
                setData(data.data)
            }else{
                setError(data.message || 'Failed to fetch data');
            }
        } catch (error: any) {
            console.error('Error during GET request:', error);
            setError(error.message || 'Failed to fetch data');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [endpoint]);

    return { data, loading, error };
};

export default useFetchData;



//HOW TO USE:

// const endpoint = '/example';

// const { data, loading, error } = useFetchData(endpoint);

// {loading && <div>Loading...</div>}

// {error && <div>Error: {error}</div>}
